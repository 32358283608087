.circle {
  width: 42px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.circle-top {
  margin: -21px auto auto;
}

.circle-black {
  border-color: black;
  background: black;
  color: white;
}

.circle-green {
  border-color: green;
  background: green;
  stroke: white;
}

@import '~@porsche-design-system/utilities/scss';

.app {
  max-width: 1920px;
  margin: auto;
}

body {
  width: 100vw;
  overflow-x: hidden;

  &.no-scrollbar-y {
    -ms-overflow-style: none; /* IE, Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      /* Chrome, Safari, Opera */
      display: none;
    }
  }

  &.disable-scroll-y {
    overflow-y: hidden;
  }
}

#root {
  margin: 0;
  padding: 0;
}

// hack patch to fix "New credit card" label not visible on payment widget
slpayment-p-text.label {
  overflow: visible;
  clip: initial;
  clip-path: initial;
}

#payment-widget {
  padding: 20px 0 0 0 !important;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition: none !important;
  }

  .pui-input__label,
  .pui-nav-mobile__overlay,
  .pui-Select-label,
  .pui-text-area__label,
  .pui-toast--slide-enter-active,
  .pui-toast--slide-exit-active {
    transition: none !important;
  }
}

.neutral-contrast-low-border {
  border-color: $p-color-neutral-contrast-low;
}

.notification-success-border {
  border-color: $p-color-notification-success;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.neutral-contrast-medium-bg {
  background: $p-color-neutral-contrast-medium;
}

.neutral-contrast-medium {
  color: $p-color-neutral-contrast-medium;
}

.neutral-contrast-low {
  color: $p-color-neutral-contrast-low;
}

.surface-bg {
  background: $p-color-background-surface;
}

.right {
  align-content: flex-end;
}

.large-space-top {
  margin-top: $p-spacing-48;
}

.large-space-bottom {
  margin-bottom: $p-spacing-48;
}

.large-space-left {
  margin-left: $p-spacing-48;
}

.large-space-right {
  margin-right: $p-spacing-48;
}

.big-space-top {
  margin-top: $p-spacing-64;
}

.big-space-bottom {
  margin-bottom: $p-spacing-64;
}

.huge-space-bottom {
  margin-bottom: 128px;
}

.huge-space-top {
  margin-top: 128px;
}

.big-space-left {
  margin-left: $p-spacing-64;
}

.big-space-right {
  margin-right: $p-spacing-64;
}

.medium-space-top {
  margin-top: $p-spacing-32;
}
.medium-space-bottom {
  margin-bottom: $p-spacing-32;
}

.medium-space-left {
  margin-left: $p-spacing-32;
}
.medium-space-right {
  margin-right: $p-spacing-32;
}

.small-space-top {
  margin-top: $p-spacing-16;
}
.small-space-bottom {
  margin-bottom: $p-spacing-16;
}

.small-space-left {
  margin-left: $p-spacing-16;
}
.small-space-right {
  margin-right: $p-spacing-16;
}

.xsmall-space-top {
  margin-top: $p-spacing-8;
}
.xsmall-space-bottom {
  margin-bottom: $p-spacing-8;
}

.xxsmall-space-top {
  margin-top: $p-spacing-4;
}
.xxsmall-space-bottom {
  margin-bottom: $p-spacing-4;
}

.card-box {
  width: available;
  margin-top: 48px;
  margin-bottom: 48px;
  padding: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

body {
  padding: 0;
  margin: 0;
}

.kbb-content {
  margin: 10px auto;
  padding: 50px;
}

footer {
  background: $p-color-theme-dark-background-surface;
  color: white;
  min-height: 200px;
  padding-left: $p-spacing-16;
  padding-right: $p-spacing-16;
}

.footer-content {
  width: available;
  height: 100%;
  white-space: pre-wrap;
  margin-left: 5rem;
  margin-right: 5rem;
}

.surface-box {
  background: #f2f2f2;
  padding: 16px;
}

.right-border-neutral {
  border-right: 1px solid $p-color-background-surface;
}

.bottom-border-neutral {
  border-right: 1px solid $p-color-background-surface;
}

.is-block {
  display: block;
}

.hidden {
  &--mobile {
    display: none;
  }
}

.two-column {
  &--divider {
    height: 100%;
    border-right: 1px solid $p-color-background-surface;
  }
}

.center-set {
  display: flex;
  justify-content: center;
  align-items: center;
}

[role='button'] {
  cursor: pointer;

  &:focus-visible {
    outline: 1px solid black !important;
  }
}

.ada-text-width {
  max-width: 75ch;
}

.button-link {
  background: none;
  text-decoration: underline;
  color: black;
  padding: 0;
  border: none;
  font-size: 1rem;
  line-height: 1.5;
  font-family: 'Porsche Next', 'Arial Narrow', Arial, sans-serif;
  font-weight: 400;

  &:hover {
    cursor: pointer;
    color: #d5001c;
  }
}

p-button-pure {
  text-decoration: underline;

  &:hover {
    color: $p-color-brand;
  }
}

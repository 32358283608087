@import '~@porsche-design-system/utilities/scss';

.checkout2-progress-bar {
  border-bottom: 1px solid $p-color-background-surface;

  .step {
    position: relative;
    height: 115px;
    width: 115px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-top: 8px;
      font-size: 14px;
      white-space: normal;
      text-align: center;
    }

    p-icon {
      padding: 8px;
      border-radius: 50%;
    }

    &.current {
      border-bottom: 2px solid red;

      .circle-border {
        border-radius: 50%;
        border: 2px solid black;
        width: 62px;
        height: 62px;
        position: absolute;
        top: -2px;
      }

      p-icon {
        padding: 8px;
        border-radius: 50%;
        color: white;
        background-color: black;
      }
      span {
        font-weight: bold;
        color: $p-color-state-active;
      }
    }

    &.complete {
      &:hover {
        cursor: pointer;
        color: $p-color-state-hover;

        p-icon {
          transform: scale(1.1);
        }
      }

      span {
        transition: color 0.3s;
      }

      p-icon {
        color: white;
        transition: transform 0.3s;
        background-color: $p-color-notification-success;
      }
    }

    &.clear {
      p-icon {
        color: gray;
      }
    }
  }
}

@import 'node_modules/@porsche-design-system/utilities/scss';

.checkout-progress-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 15px 0;

  &.center {
    justify-content: center;
  }
  &.space-between {
    margin-left: 40px;
    justify-content: space-between;
  }

  .price-and-summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .price-summary-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px 20px 20px;
    border: 3px solid transparent;
    cursor: pointer;
  }
}

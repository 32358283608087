@import '~@porsche-design-system/utilities/scss';

.confirmation-order-number-box {
  border: 1px solid $p-color-notification-success;
  padding: 25px 10px;

  .check-icon {
    border: 1px solid $p-color-notification-success;
    border-radius: 25px;
    padding: 3px;
    margin-right: 10px;
  }
}

.big-padding-bottom {
  padding-bottom: $p-spacing-64;
}

.autocheck-summary2-container {
  background: url('/public/images/confirmation/autocheck-bg.jpg');
  background-size: cover;
  padding: 100px;
  margin: 100px 0;

  @include p-media-query('xxs') {
    padding: 10px;
  }

  .autocheck-summary {
    padding: 30px;

    p-grid-item {
      margin: 0;
      background: white;

      &:first-of-type {
        padding-top: 30px;
      }
      &:last-of-type {
        padding-bottom: 30px;
      }
    }
  }
}

:root {
  --balloon-border-radius: 2px;
  --balloon-color: grey;
  --balloon-move: 10px;
}

button[data-balloon-text][data-balloon-pos] {
  overflow: visible;
}

[data-balloon-text][data-balloon-pos] {
  position: relative;
  cursor: pointer;
}
[data-balloon-text][data-balloon-pos]:after {
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-out 0.18s;
  text-indent: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  text-shadow: none;
  background: white;
  color: black;
  border-radius: 2px;
  border: 1px solid grey;
  content: attr(data-balloon-text);
  padding: 0.5em 1em;
  position: absolute;
  white-space: nowrap;
  z-index: 100;
}
[data-balloon-text][data-balloon-pos]:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: var(--balloon-color);
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-out 0.18s;
  content: '';
  position: absolute;
  z-index: 10;
}
[data-balloon-text][data-balloon-pos]:hover:before,
[data-balloon-text][data-balloon-pos]:hover:after,
[data-balloon-text][data-balloon-pos][data-balloon-visible]:before,
[data-balloon-text][data-balloon-pos][data-balloon-visible]:after,
[data-balloon-text][data-balloon-pos]:not([data-balloon-nofocus]):focus:before,
[data-balloon-text][data-balloon-pos]:not([data-balloon-nofocus]):focus:after {
  opacity: 1;
  pointer-events: none;
}
[data-balloon-text][data-balloon-pos][data-balloon-break]:after {
  white-space: pre;
}
[data-balloon-text][data-balloon-pos][data-balloon-break][data-balloon-length]:after {
  white-space: pre-line;
  word-break: break-word;
}
[data-balloon-text][data-balloon-pos][data-balloon-blunt]:before,
[data-balloon-text][data-balloon-pos][data-balloon-blunt]:after {
  transition: none;
}
[data-balloon-text][data-balloon-pos][data-balloon-pos='up']:hover:after,
[data-balloon-text][data-balloon-pos][data-balloon-pos='up'][data-balloon-visible]:after,
[data-balloon-text][data-balloon-pos][data-balloon-pos='down']:hover:after,
[data-balloon-text][data-balloon-pos][data-balloon-pos='down'][data-balloon-visible]:after {
  transform: translate(-50%, 0);
}
[data-balloon-text][data-balloon-pos][data-balloon-pos='up']:hover:before,
[data-balloon-text][data-balloon-pos][data-balloon-pos='up'][data-balloon-visible]:before,
[data-balloon-text][data-balloon-pos][data-balloon-pos='down']:hover:before,
[data-balloon-text][data-balloon-pos][data-balloon-pos='down'][data-balloon-visible]:before {
  transform: translate(-50%, 0);
}
[data-balloon-text][data-balloon-pos][data-balloon-pos*='-left']:after {
  left: 0;
}
[data-balloon-text][data-balloon-pos][data-balloon-pos*='-left']:before {
  left: 5px;
}
[data-balloon-text][data-balloon-pos][data-balloon-pos*='-right']:after {
  right: 0;
}
[data-balloon-text][data-balloon-pos][data-balloon-pos*='-right']:before {
  right: 5px;
}
[data-balloon-text][data-balloon-pos][data-balloon-pos*='-left']:hover:after,
[data-balloon-text][data-balloon-pos][data-balloon-pos*='-left'][data-balloon-visible]:after,
[data-balloon-text][data-balloon-pos][data-balloon-pos*='-right']:hover:after,
[data-balloon-text][data-balloon-pos][data-balloon-pos*='-right'][data-balloon-visible]:after {
  transform: translate(0, 0);
}
[data-balloon-text][data-balloon-pos][data-balloon-pos*='-left']:hover:before,
[data-balloon-text][data-balloon-pos][data-balloon-pos*='-left'][data-balloon-visible]:before,
[data-balloon-text][data-balloon-pos][data-balloon-pos*='-right']:hover:before,
[data-balloon-text][data-balloon-pos][data-balloon-pos*='-right'][data-balloon-visible]:before {
  transform: translate(0, 0);
}
[data-balloon-text][data-balloon-pos][data-balloon-pos^='up']:before,
[data-balloon-text][data-balloon-pos][data-balloon-pos^='up']:after {
  bottom: 100%;
  transform-origin: top;
  transform: translate(0, var(--balloon-move));
}
[data-balloon-text][data-balloon-pos][data-balloon-pos^='up']:after {
  margin-bottom: 10px;
}
[data-balloon-text][data-balloon-pos][data-balloon-pos='up']:before,
[data-balloon-text][data-balloon-pos][data-balloon-pos='up']:after {
  left: 50%;
  transform: translate(-50%, var(--balloon-move));
}
[data-balloon-text][data-balloon-pos][data-balloon-pos^='down']:before,
[data-balloon-text][data-balloon-pos][data-balloon-pos^='down']:after {
  top: 100%;
  transform: translate(0, calc(var(--balloon-move) * -1));
}
[data-balloon-text][data-balloon-pos][data-balloon-pos^='down']:after {
  margin-top: 10px;
}
[data-balloon-text][data-balloon-pos][data-balloon-pos^='down']:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom-color: var(--balloon-color);
}
[data-balloon-text][data-balloon-pos][data-balloon-pos='down']:after,
[data-balloon-text][data-balloon-pos][data-balloon-pos='down']:before {
  left: 50%;
  transform: translate(-50%, calc(var(--balloon-move) * -1));
}
[data-balloon-text][data-balloon-pos][data-balloon-pos='left']:hover:after,
[data-balloon-text][data-balloon-pos][data-balloon-pos='left'][data-balloon-visible]:after,
[data-balloon-text][data-balloon-pos][data-balloon-pos='right']:hover:after,
[data-balloon-text][data-balloon-pos][data-balloon-pos='right'][data-balloon-visible]:after {
  transform: translate(0, -50%);
}
[data-balloon-text][data-balloon-pos][data-balloon-pos='left']:hover:before,
[data-balloon-text][data-balloon-pos][data-balloon-pos='left'][data-balloon-visible]:before,
[data-balloon-text][data-balloon-pos][data-balloon-pos='right']:hover:before,
[data-balloon-text][data-balloon-pos][data-balloon-pos='right'][data-balloon-visible]:before {
  transform: translate(0, -50%);
}
[data-balloon-text][data-balloon-pos][data-balloon-pos='left']:after,
[data-balloon-text][data-balloon-pos][data-balloon-pos='left']:before {
  right: 100%;
  top: 50%;
  transform: translate(var(--balloon-move), -50%);
}
[data-balloon-text][data-balloon-pos][data-balloon-pos='left']:after {
  margin-right: 10px;
}
[data-balloon-text][data-balloon-pos][data-balloon-pos='left']:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-left-color: var(--balloon-color);
}
[data-balloon-text][data-balloon-pos][data-balloon-pos='right']:after,
[data-balloon-text][data-balloon-pos][data-balloon-pos='right']:before {
  left: 100%;
  top: 50%;
  transform: translate(calc(var(--balloon-move) * -1), -50%);
}
[data-balloon-text][data-balloon-pos][data-balloon-pos='right']:after {
  margin-left: 10px;
}
[data-balloon-text][data-balloon-pos][data-balloon-pos='right']:before {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-right-color: var(--balloon-color);
}
[data-balloon-text][data-balloon-pos][data-balloon-length]:after {
  white-space: normal;
}
[data-balloon-text][data-balloon-pos][data-balloon-length='small']:after {
  width: 80px;
}
[data-balloon-text][data-balloon-pos][data-balloon-length='medium']:after {
  width: 150px;
}
[data-balloon-text][data-balloon-pos][data-balloon-length='large']:after {
  width: 260px;
}
[data-balloon-text][data-balloon-pos][data-balloon-length='xlarge']:after {
  width: 75%;
}
[data-balloon-text][data-balloon-pos][data-balloon-length='fit']:after {
  width: auto;
}

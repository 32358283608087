@import '~@porsche-design-system/utilities/scss';

.card {
  width: available;
  background-color: white;
  margin-top: $p-spacing-48;
  margin-bottom: $p-spacing-48;
  box-shadow: rgb(222, 222, 222) 0 0 40px 0;
  border: 1px solid transparent;
  border-image: initial;
  transition: box-shadow 0s ease 0s, border-color 0.15s ease 0s;

  &:hover {
    border: 1px solid rgb(201, 202, 203);
    border-image: initial;
  }
}

.card-content-wrapper {
  margin-left: $p-spacing-32;
}

.tile {
  background: white;
}

.lock--btn {
  border-radius: 50%;
  margin-right: $p-spacing-32;
  padding: $p-spacing-8;
}

.pui-content-wrapper {
  > div.pui-content-wrapper__child {
    padding: 0;
  }
}

button {
  width: auto;
}

.medium-space-left {
  margin-left: $p-spacing-16;
}

.medium-space-right {
  margin-right: $p-spacing-16;
}

@media only screen and (min-width: 480px) and (max-width: 760px) and (orientation: landscape) {
  .card {
    margin-top: $p-spacing-72;
  }
}

@media only screen and (min-width: 760px) and (max-width: 999px) and (orientation: landscape) {
  .card {
    margin-top: $p-spacing-40;
  }
}

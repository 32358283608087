@import '~@porsche-design-system/utilities/scss';

.old-purchase-confirmation {
  background: $p-color-background-default;

  .hero {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 42.86%;
  }

  .hero-image {
    position: absolute;
    bottom: -10%;
    left: 0;
    width: 100%;
  }

  .hero-glass {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
  }
}

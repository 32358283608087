.step-progress-circle {
  position: relative;
  display: flex;
  justify-content: center;
  width: 80px;
  height: 80px;

  .step-progress-circle-outer {
    position: relative;
    background: lightgray;
    border-radius: 50%;
    overflow: hidden;
    width: 80px;
    height: 80px;

    .step {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      height: 100vw;
      background: green;
      transform-origin: 0 0;

      &-clear {
        background: lightgray;
      }
    }
  }

  .step-progress-circle-inner {
    position: absolute;
    display: flex;
    left: 50%;
    top: 50%;
    width: 70px;
    height: 70px;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%) translateY(-50%);
    background: #fff;
    border-radius: 50%;
  }
}

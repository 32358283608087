@import '~@porsche-design-system/utilities/scss';
@mixin base-navbar-styles {
  .navbar-separator {
    overflow-y: hidden;
    .scroll-menu-arrow.disabled {
      display: none;
    }

    .menu-item-wrapper.active {
      border-bottom: 2px solid $p-color-brand;
    }
  }
}

.styled-non-sticky-content-wrapper {
  @include base-navbar-styles;
  @include p-media-query('xxs', 's') {
    box-shadow: 1px 6px 7px #c9cacb;
  }

  .navbar-separator {
    border-bottom: 1px solid #4a4e51;
  }

  .logo-and-progress-bar-container {
    display: flex;
    flex-direction: row;
    padding-top: 32px;
  }

  .logo {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  .progress-bar {
    flex: 3;
  }
}

.styled-sticky-container {
  transition: top 0.5s;
  max-width: 1920px;

  @include base-navbar-styles;
  &.visible {
    top: 0;
  }
  &.invisible {
    top: -200px;
  }

  display: block;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 1px 1px 15px #c9cacb;
  animation: moveDown 0.5s ease-out;

  @include p-media-query('s') {
    padding: 8px 0 0;
  }

  @keyframes moveDown {
    from {
      transform: translateY(-2rem);
    }
    to {
      transform: translateY(0rem);
    }
  }

  .sticky-wrapper {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0px max(0rem, 0vw);

    .sticky-wrapper-child {
      width: 100%;
      padding: 0px max(0rem, 0vw);
    }
  }
}

@import '~@porsche-design-system/utilities/scss';

.ssl-info-container {
  display: flex;

  .ssl-info {
    margin-bottom: $p-spacing-16;
    width: 125px;

    .labels {
      display: inline-block;
    }

    .icon {
      width: $p-spacing-32;
      height: $p-spacing-32;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      fill: #2fb84d;
      background-color: #eaf9ef;
      float: right;
      margin-left: $p-spacing-8;
    }
  }
}

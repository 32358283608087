.calc {
  .main-content,
  #mainForm {
    margin: 0;
    padding: 0;
  }

  .MuiTypography-body1 {
    max-width: 75ch;
    min-width: auto;
  }

  .MuiExpansionPanel-root {
    background: #f2f2f2 !important;
  }

  .MuiButtonBase-root span {
    text-transform: capitalize;
  }

  .MuiButton-containedPrimary {
    border-radius: 0;
    padding: 8px 15px;

    span.MuiButton-label:before {
      width: 24px;
      height: 20px;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%" focusable="false"><g fill="white"><path d="M14.75 12l-6 8h1.26L16 12l-5.99-8H8.75l6 8z"></path></g></svg>');
      background-repeat: no-repeat;
      content: '';
    }
  }
}

@import '~@porsche-design-system/utilities/scss';

.divider {
  width: 100%;
  padding: 20px 0 20px;
}

.form-input {
  height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.guest-form {
  background-color: $p-color-background-surface;
  padding: $p-spacing-16 $p-spacing-16 0 $p-spacing-16;
  margin-left: 0 !important;
}

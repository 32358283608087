@import '~@porsche-design-system/utilities/scss';

.plan-wrapper {
  cursor: pointer;

  &.disabled:hover {
    cursor: no-drop;
  }
}

[data-balloon-pos]:before,
[data-balloon-pos='up-left']:after,
[data-balloon-pos='down-left']:after {
  left: 1.5% !important;
}

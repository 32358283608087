.main-content {
  flex: 3;
}

.sidebar-content {
  position: sticky;
  top: 16vh;
  z-index: 10;
  height: 80vh;
  margin-top: 50px;
  overflow-y: auto;
  flex: 2;
}

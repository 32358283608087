.user-progress-bar {
  display: flex;
  justify-content: space-evenly;
  min-height: 65px;

  .user-progress-bar-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    .step-line {
      border-bottom: 1px solid lightgrey;
      width: 100%;
      margin-bottom: 5px;
    }

    &:first-child .step-line:first-child,
    &:last-child .step-line:last-child {
      border: 0;
      margin: 0;
    }
  }

  .step-line {
    display: flex;

    & > div {
      width: 50%;
    }
  }
}

@media (max-width: 450px) {
  .user-progress-bar {
    transform: scale(0.85);
  }
}

.step-name {
  transition: opacity 0.35s;
  white-space: pre;
}

@media (max-width: 1000px) {
  .step-name {
    transform: scale(0.75);
  }
}

.step-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 18px 0;
  width: 100%;
}

.step-number-container {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin: 0 25px;
  flex-shrink: 0;
}

.step-number,
.step-check {
  position: absolute;
}

.step-number {
  transition: background-color 0.35s;
}

.step-check {
  color: white;
  opacity: 0;
  transition: opacity 0.35s;

  & > i {
    transform: translateY(-5%);
  }
}

.current .step-number {
  color: white;
}

.complete {
  .step-check {
    opacity: 1;
  }

  .step-number {
    opacity: 0;
  }
}

.checkout,
.checkout2 {
  .step-number,
  .step-check {
    height: 30px;
    width: 30px;
  }

  .step-number {
    padding: 4px 0 0 1px;
  }

  .step-name {
    color: black;
  }

  .clear {
    .step-number-container {
      box-sizing: border-box;
      border: 1px solid gray;
    }

    .step-number {
      color: gray;
      padding: 3px 0 0;
    }

    .step-name {
      color: gray;

      &:hover {
        cursor: default;
      }
    }
  }

  .current {
    .step-number-container {
      background-color: black;
    }

    .step-name:hover {
      cursor: default;
    }
  }

  .complete {
    .step-number-container {
      background-color: green;

      &:hover {
        cursor: pointer;
      }
    }

    .step-name:hover {
      cursor: pointer;
    }
  }
}

.flyout {
  .step-number,
  .step-check {
    color: white;
  }

  .step-name {
    color: gray;
  }

  .step-number {
    padding: 4px 0 0 11px;
  }

  .complete {
    .step-number-container {
      background-color: black;
    }
  }

  .clear {
    .step-number-container {
      background-color: lightgrey;
    }
  }

  .current {
    .step-number-container {
      background-color: rgb(145, 32, 24);
    }

    .step-name {
      color: black;
    }
  }
}

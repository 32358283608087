@import '~@porsche-design-system/utilities/scss';

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid rgb(227, 228, 229);
  background-color: white;

  .logo-container {
    background-color: white;
    border: 1px solid rgb(227, 228, 229);
    padding: 19px 4px 13px;
  }
}

@import '~@porsche-design-system/utilities/scss';

.circle2 {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  p-icon {
    padding: 8px;
    border-radius: 50%;
  }

  &.current {
    .circle-border {
      border-radius: 50%;
      border: 4px solid black;
      width: 52px;
      height: 52px;
      position: absolute;
    }

    p-icon {
      background-color: black;
    }
  }

  &.complete {
    p-icon {
      background-color: $p-color-notification-success;
    }
  }
}

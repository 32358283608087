.step-progress-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 40px;
  padding: 15px 0;

  .step-progress-labels {
    flex: 3;
  }
}

@import '~@porsche-design-system/utilities/scss';

.confirmation2-container {
  .confirmation-header-image {
    position: absolute;
    height: 200%;
    overflow: hidden;
    z-index: -1;

    img {
      position: sticky;
      width: 100%;
      z-index: -2;
      top: 0;

      @for $i from 3 through 18 {
        @include p-media-query($i * 100px) {
          width: calc(1800% / #{$i});
          margin-left: calc(-2000px / #{$i});
        }
      }

      @include p-media-query(1500px) {
        margin-left: 0;
      }

      @include p-media-query(1800px) {
        width: 100%;
      }

      @include p-media-query(1920px) {
        width: 1920px;
      }
    }

    .header-image-gradient {
      position: absolute;
      height: 90%;
      width: 100%;
      margin-top: -55vh;
      z-index: -1;
      background: rgb(0, 0, 0);
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
    }
  }

  .journey-steps {
    gap: 25px;

    @include p-media-query('m') {
      height: 100%;
    }

    .journey-step {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: white;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 20px;
      height: 100%;

      .journey-step-circle {
        width: 80px;
        height: 80px;
        border: 4px solid black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Porsche Next;
        font-weight: 600;
      }
    }
  }

  .confirmation-order-number-box {
    border: 2px solid white;
    padding: 20px;
    width: 75%;
    margin: 125px auto 150px;

    .green-container {
      background-color: #e5f3e7;
      padding: 20px;
    }
  }
}

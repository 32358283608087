@import '~@porsche-design-system/utilities/scss';

.mobile-vehicle-summary {
  background: $p-color-background-surface;
}

.mobile-vehicle-summary-details {
  padding: $p-spacing-32;

  @include p-media-query('xxs', 's') {
    padding: $p-spacing-32 $p-spacing-16;
  }
}

.mobile-vehicle-summary-modal-arrow {
  border: 0;
  height: 100%;
  padding: 0 $p-spacing-24;
  background-color: transparent;
  @include p-media-query('xxs', 's') {
    padding: 0 $p-spacing-16;
  }

  &:active {
    animation: 1s background-blink;
  }

  *:active {
    animation: 1s arrow-blink;
  }

  @keyframes background-blink {
    50% {
      background-color: $p-color-theme-light-default;
    }
    100% {
      background-color: transparent;
    }
  }

  @keyframes arrow-blink {
    50% {
      color: $p-color-background-default;
    }
    100% {
      color: $p-color-theme-light-default;
    }
  }
}

.mobile-vehicle-summary-modal-arrow-icon {
  width: 60px;
  @include p-media-query('xxs', 's') {
    width: 50px;
  }
}

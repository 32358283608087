@import '~@porsche-design-system/utilities/scss';

.trade-in {
  &--form {
    background: $p-color-background-surface;
    padding: $p-spacing-16 0 $p-spacing-16 $p-spacing-16;
  }

  &--input-field {
    margin-top: $p-spacing-8;
  }

  &--input-field-set {
    margin: 0 0 $p-spacing-16;
  }

  &--item {
    padding-right: $p-spacing-16;
  }

  &--optional-section {
    margin-top: $p-spacing-32;
  }
}

@import '~@porsche-design-system/utilities/scss';

.checkout2-app-header {
  height: 0;

  .logo-container {
    border: 1px solid rgb(227, 228, 229);
    height: 85px;
    width: 150px;
    padding: 5px 0 0 20px;
    margin-left: 25px;
    background-color: white;
  }
}

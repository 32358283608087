.card2 {
  background-color: white;
  border: 1px solid rgb(201, 202, 203);
  padding: 0 0 0 10px;

  &.no-border {
    background-color: white;
    border: none;
    box-shadow: none;
  }

  &.card-border {
    border: 1px solid black;
    box-sizing: border-box;
    box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.25);
  }

  &.card-content {
    margin: 0;
  }
}

@import '~@porsche-design-system/utilities/scss';

.your-porsche {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vehicle-summary-modal {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgb(255, 255, 255);
  overflow-y: scroll;
  padding: 42px 35px 35px;

  &:not(:focus-within) {
    background-color: rgb(255, 255, 254);
    transition: background-color 0.01s;
  }

  &.hidden {
    display: none;
    transform: translateX(100%);
  }

  &.open {
    animation: modal-open 200ms ease-out;
  }
  &.close {
    animation: modal-close 200ms ease-in;
  }

  .close-modal-arrow {
    display: block;
    position: absolute !important;
    margin-top: 5px;
  }
}

@keyframes modal-open {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes modal-close {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(1000%);
  }
}

@import '~@porsche-design-system/utilities/scss';

.payment-card-skeleton {
  .card-number-info {
    @media (min-width: 0) and (max-width: 543px), (min-width: 1000px) and (max-width: 1029px) {
      flex-direction: column;

      .card-expiration {
        margin-left: 0 !important;
        margin-top: 18px;
      }
    }
  }
}

.cta-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  p-button-group {
    width: 100%;
    padding: 0 8px;
  }

  @media (max-width: 520px) {
    flex-direction: column;
    align-items: center;
  }
}

@import '~@porsche-design-system/utilities/scss';

.sidebar-inner {
  margin-left: 20px;
  padding: 0 30px 30px;

  .sidebar-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }
}
